@use "./functions" as *;


@mixin indicatorGrid {
  width: 100%;
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 1280px) {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: repeat(6, 1fr);
    width: 100%;
    grid-gap: 8px;

    .div1 {
      grid-column: gridSpander(1, 4);
      grid-row: gridSpander(1,4);
    }

    .div2 {
      grid-column: gridSpander(5, 4);
      grid-row: gridSpander(1,2);
    }

    .div3 {
      grid-column: gridSpander(9, 5);
      grid-row: gridSpander(1, 2);
    }

    .div4 {
      grid-column: gridSpander(5, 4);
      grid-row: gridSpander(3, 2);
    }

    .div5 {
      grid-column: gridSpander(9, 5);
      grid-row: gridSpander(3, 2);
    }

    .div6 {
      grid-column: gridSpander(1, 8);
      grid-row: gridSpander(5, 2);
    }

    .div7 {
      grid-column: gridSpander(9, 5);
      grid-row: gridSpander(5, 2);
    }

  }
}